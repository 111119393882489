<template>
  <scrollable-container>
    <placeholder
      v-show="state === reportEnum.requestAwaiting"
      :text="text"
      @buttonClick="buildReport"
    />
    <loader v-show="state === reportEnum.tableLoading" />
    <container
      v-show="state === reportEnum.tableReady"
      id="printable"
      :class="[
        { [$style.message]: state === reportEnum.requestAwaiting },
        $style.scrollableContent,
      ]"
    >
      <stat-table
        :class="$style.table"
        :columns="columns"
        :table-data="tableData"
      />
    </container>

    <container
      v-show="state === reportEnum.tableReady"
      :class="$style.notScrollableContent"
    >
      <tabset
        :tabs="tabs"
        :opened="opened"
        :hidden-text="hiddenText"
        :tab-style="tabStyle"
        @tabClick="openNewTab"
        @toggle="toggle"
      />
      <barchart
        v-show="tabs[0].active && opened"
        :bars="mostVisitedSites.topList"
        :rect-style="mostVisitedSites.rectStyle"
        :text-style="mostVisitedSites.textStyle"
        :is-convert-needed="true"
      />
      <barchart
        v-show="tabs[1].active && opened"
        :bars="mostActivePC.topList"
        :rect-style="mostActivePC.rectStyle"
        :text-style="mostActivePC.textStyle"
        :is-convert-needed="false"
      />
      <barchart
        v-show="tabs[2].active && opened"
        :bars="mostProductivePC.topList"
        :rect-style="mostProductivePC.rectStyle"
        :text-style="mostProductivePC.textStyle"
        :is-convert-needed="false"
      />
      <barchart
        v-show="tabs[3].active && opened"
        :bars="mostUnproductivePC.topList"
        :rect-style="mostUnproductivePC.rectStyle"
        :text-style="mostUnproductivePC.textStyle"
        :is-convert-needed="false"
      />
    </container>
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import StatTable from '@/views/reports/statistics/stat-table.vue';
import { getWebsiteStat } from '@/api/methods/reports/statistics/index';
import Tabset from '@/components/common/tabs/single-view-tabs.vue';
import Barchart from '@/components/common/bars/top-bar-chart.vue';
import Loader from '@/components/common/blocks/loader.vue';
import { getDataField } from '@/views/reports/users/worktime/table-field';

export default {
  components: {
    ScrollableContainer,
    Container,
    Placeholder,
    StatTable,
    Tabset,
    Barchart,
    Loader,
  },
  data: () => ({
    name: 'Отчет по посещенным сайтам',
    tableData: [],
    computersArray: [],
    opened: true,
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    text: 'Для построения отчета нажмите кнопку',
    hiddenText: 'ТОП 10 - показать',
    tabStyle: {
      padding: '14px 10px 17px 10px',
    },
    columns: [
      { name: 'Сайт', sort: 1, width: '2', field: 'link' },
      { name: 'Активность', sort: 0, width: '1', field: 'duration' },
      { name: 'Продуктивность', sort: 0, width: '1', field: 'productivity' },
    ],
    tabs: [
      {
        title: 'ТОП 10 посещенных сайтов',
        active: true,
      },
      {
        title: 'ТОП 10 ПК по кол-ву посещенных сайтов',
        active: false,
      },
      {
        title: 'ТОП 10 ПК по продуктивным сайтам',
        active: false,
      },
      {
        title: 'ТОП 10 ПК по непродуктивным сайтам',
        active: false,
      },
    ],
    mostVisitedSites: {
      topList: [],
      rectStyle: {
        height: '17px',
        fill: '#FFECBC',
      },
      textStyle: {
        fill: '#D49A02',
        fontSize: '12px',
        letterSpacing: '-0.24px',
      },
    },
    mostActivePC: {
      topList: [],
      rectStyle: {
        height: '17px',
        fill: '#94C2E0',
      },
      textStyle: {
        fill: '#1970A7',
        fontSize: '12px',
        letterSpacing: '-0.24px',
      },
    },
    mostProductivePC: {
      topList: [],
      rectStyle: {
        height: '17px',
        fill: '#7CD890',
      },
      textStyle: {
        fill: '#1F8D37',
        fontSize: '12px',
        letterSpacing: '-0.24px',
      },
    },
    mostUnproductivePC: {
      topList: [],
      rectStyle: {
        height: '17px',
        fill: '#FFB0B0',
      },
      textStyle: {
        fill: '#BB5656',
        fontSize: '12px',
        letterSpacing: '-0.24px',
      },
    },
  }),
  computed: {
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];

      return tree;
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },

  methods: {
    openNewTab(index) {
      this.tabs.forEach((item) => {
        item.active = false;
      });

      this.tabs[index].active = true;
    },
    toggle() {
      this.opened = !this.opened;
    },
    getCheckedComputersFromTree(node) {
      if (node.type === 'computer' && node.checked) {
        this.computersArray.push(node);
      }
      if (node.type === 'folder') {
        node.children.forEach((child) => this.getCheckedComputersFromTree(child));
      }
    },

    buildReport() {
      this.state = this.reportEnum.tableLoading;
      this.tableData = [];
      this.computersArray = [];

      if (this.computersTree) {
        this.computersTree.forEach((node) => this.getCheckedComputersFromTree(node));
      }

      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersArray
      ) {
        this.promise = getWebsiteStat(
          this.computersArray,
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
        ).then(({ data }) => {
          if (data) {
            this.tableData = data.result.users;
            this.mostVisitedSites.topList = data.result.topSites;
            this.mostActivePC.topList = data.result.topPC;
            this.mostProductivePC.topList = data.result.productivePC;
            this.mostUnproductivePC.topList = data.result.unProductivePC;

            this.state = this.reportEnum.tableReady;

            const exportArray = [];

            for (let i = 0; i < data.result.users.length; i++) {
              const name = data.result.users[i].userName;

              Object.values(data.result.users[i].history).forEach((value) => {
                const category = value.categoryName;

                for (let y = 0; y < value.events.length; y++) {
                  const resObj = { Пользователь: name, Категория: category };
                  for (let j = 0; j < this.columns.length; j++) {
                    resObj[this.columns[j].name] = getDataField(
                      value.events[y][this.columns[j].field],
                      this.columns[j].field,
                    );
                  }
                  exportArray.push(resObj);
                }
              });
            }

            this.$store.commit('exportData/setWebsites', { name: this.name, data: exportArray });
          } else this.state = this.reportEnum.requestAwaiting;
        });
      }
      return this.tableData;
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notScrollableContent {
  margin: 20px 8px 0 0;
  padding: 0 12px 10px 20px;
  overflow: unset;
}
</style>
